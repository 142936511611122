<template>
    <div>
    <v-container>
        <br/>
        <v-row>
            <v-col cols="12" md="12" lg="6">
                <div class=" box pa-5">
                    <div class=" pa-5">
                        <v-form v-model="validBatch">
                            <h4>Gestión de Lotes del Almacén</h4>
                            <br/>
                            <v-row align="center">
                                <v-col cols="6">
                                    <v-text-field outlined dense 
                                    v-model="batch.ID_ItemBatch"
                                    label="Ingrese Lote:" 
                                    placeholder="Número del lote"
                                    required
                                    :disabled="updateBatch"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="6">
                                    <date-picker label="Fecha de expiración del Lote"
                                                @dateSelected="(param) => (batch.ExpirationDate = param)"
                                                v-model="batch.ExpirationDate" ></date-picker>
                                </v-col>

                                <v-col cols="6">
                                    <div class="text-center">
                                        <v-btn @click.prevent="addBatch()" class="btn-add">Agregar lote</v-btn>
                                    </div>
                                </v-col>

                                <v-col cols="6">
                                    <div class="text-center">
                                        <v-btn @click.prevent="toUpdateBatch()" class="btn-add" :disabled="!updateBatch">Modificar lote</v-btn>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-form>
                        <br/>

                    
                        <div class="d-flex filter-btn" style="left: 0.8rem;top: 3.2rem; z-index: 20; position: relative ;">
                            <v-bottom-sheet v-model="showFilter1" inset>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="btnclean" v-bind="attrs" v-on="on">FILTROS
                                    </v-btn>
                                </template>
                                <v-sheet class="text-center">
                                    <v-btn class="mt-6 btndelete"  @click="showFilter1 = !showFilter1">Cerrar</v-btn>
                                    <v-btn class="mt-6 btndelete" >Filtros</v-btn>
                                    <div class="my-3 pa-4">
                                        <div>
                                            <date-picker label="From Date"></date-picker>
                                            <date-picker label="To Date"></date-picker>
                                        </div>
                                    </div>
                                </v-sheet>
                            </v-bottom-sheet>
                        </div>
                        <!-- Needs filter -->
                        <DxDataGrid class="isScrolledByGeneralFilter" :data-source="batchs" key-expr="ID_ItemBatch"
                            :show-row-lines="true"
                            :show-borders="true"
                            :row-alternation-enabled="true"
                            style="padding-top: .4rem; z-index: 10;"
                            
                        >
                            <DxScrolling row-rendering-mode="virtual"/>
                            <DxPaging :page-size="10"/>
                            <DxColumn :width="80" cell-template="show-template" caption=""></DxColumn>
                            <DxColumn data-field="ID_ItemBatch" caption="ID del Lote"></DxColumn>
                            <DxColumn data-field="ExpirationDate" caption="Fecha de Expiración"></DxColumn>

                            <template #show-template="{data}">
                                <span>
                                <a href="#" class="mx-1" @click.prevent="showBatch(data.data)">
                                    <v-icon color="primary">mdi-eye</v-icon>
                                </a>

                                <a href="#" @click.prevent="confirmDeleteBatch(data.data)">
                                    <v-icon small color="error">mdi-delete</v-icon>
                                </a>
                                </span>
                            </template>
                        </DxDataGrid>
                    </div>
                </div>
            </v-col>

            <v-col cols="6" md="12" lg="6">
                <div class="box pa-5">
                    <v-card class=" pa-5" style="border: none;box-shadow: none;">
                        <v-form v-model="validBatch" >
                            <h4>Gestión de Grupos de Contabilidad de Productos</h4>
                            <br/>
                            <v-row align="center">
                                <v-col cols="12">
                                    <v-text-field outlined dense 
                                    v-model="accountinggroup.AccountingLineName"
                                    label="Nombre de la familía" 
                                    placeholder="Nombre de la familía contable"
                                    required
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12">
                                    <v-text-field outlined dense 
                                    v-model="accountinggroup.AccountingCategoryName"
                                    label="Nombre de la categoría contable" 
                                    placeholder="Nombre de la categoría contable"
                                    required
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12">
                                    <v-text-field outlined dense 
                                    v-model="accountinggroup.AccountingSubCategoryName"
                                    label="Nombre de la subcategoría contable" 
                                    placeholder="Nombre de la subcategoría contable"
                                    required
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="6">
                                    <div class="text-center">
                                        <v-btn class="btn-add" @click.prevent="addAccountingGroup()">Agregar Grupo Contable</v-btn>
                                    </div>
                                </v-col>

                                <v-col cols="6">
                                    <div class="text-center">
                                        <v-btn class="btn-add" @click.prevent="toUpdateAccountingGroup()" :disabled="!updateAccountinggroup">Modificar<br/>Grupo Contable</v-btn>
                                    </div>
                                </v-col>
                            </v-row>
                           
                        </v-form>
                    </v-card>

                    

                    <v-card class=" pa-5" style="border: none;box-shadow: none;">
                        <div class="d-flex filter-btn" style="left: 0.8rem;top: 3.2rem; z-index: 20; position: relative ;">
                            <v-bottom-sheet v-model="showFilter2" inset>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="btnclean" v-bind="attrs" v-on="on">FILTROS
                                    </v-btn>
                                </template>
                                <v-sheet class="text-center">
                                    <v-btn class="mt-6 btndelete" @click="showFilter2 = !showFilter2">Cerrar</v-btn>
                                    <v-btn class="mt-6 btnclean" >Filtros</v-btn>
                                    <div class="my-3 pa-4">
                                        <div>
                                            <date-picker label="From Date"></date-picker>
                                            <date-picker label="To Date"></date-picker>
                                        </div>
                                    </div>
                                </v-sheet>
                            </v-bottom-sheet>
                        </div>
                        <DxDataGrid class="isScrolledByGeneralFilter" :data-source="accountinggroups" key-expr="ID_AccountingLine"
                            :show-row-lines="true"
                            :show-borders="true"
                            :row-alternation-enabled="true"
                        >
                            <DxScrolling row-rendering-mode="virtual"/>
                            <DxPaging :page-size="10"/>
                            <DxColumn :width="80" cell-template="show-template" caption=""></DxColumn>
                            <DxColumn data-field="AccountingLineName" caption="Familía"></DxColumn>
                            <DxColumn data-field="AccountingCategoryName" caption="Categoría"></DxColumn>
                            <DxColumn data-field="AccountingSubCategoryName" caption="Subcategoría"></DxColumn>

                            <template #show-template="{data}">
                                <span>
                                    <a href="#" class="mx-1" @click.prevent="showAccountingGroup(data.data)">
                                        <v-icon color="primary">mdi-eye</v-icon>
                                    </a>
                                    <a href="#" @click.prevent="confirmDeleteAccountingGroup(data.data)">
                                        <v-icon small color="error">mdi-delete</v-icon>
                                    </a>
                                </span>
                            </template>
                        </DxDataGrid>
                    </v-card>
                </div>
            </v-col>
        </v-row>
        <br/>
    </v-container>
    <alerts
      v-if="alert.show"
      v-on:close_alert="closeAlert"
      v-on:accept_alert="acceptAlert"
      :properties="alert"
    ></alerts>
   </div>
</template>

<script>
    import DatePicker from "../../../components/DatePicker";
    import {DxDataGrid, DxColumn,DxScrolling, DxPaging} from 'devextreme-vue/data-grid';
    import Alerts from"@/components/Alerts"
    export default {
        name: 'ManageBatchAndAccountingGroups',
        props: ['data','win'],
        components: {DatePicker, DxDataGrid, DxColumn, DxScrolling, DxPaging, Alerts},
        data() {
            return {
                alert: {
                type: "success",
                show: false,
                header: "",
                body: "",
                },
                batch: {},
                oldBatch: {},
                accountinggroup: {},
                oldAccountinggroup: {},
                validBatch: false,
                validAccountinggroup: false,
                updateBatch: false,
                updateAccountinggroup: false,
                showFilter1: false,
                showFilter2: false,
                batchs: {},
                accountinggroups: {},
                search: {},
                user: null,
            }
        },
        mounted() {
            let idbusiness = JSON.parse(localStorage.getItem("user")).businessid || ""
            let idbranch = JSON.parse(localStorage.getItem("user")).branch || ""
            this.user = JSON.parse(localStorage.getItem("user")).id || ""
            this.search.business = idbusiness
            this.search.branch = idbranch
            this.search.warehouse = this.data.ID_Warehouse
            this.showBatchs()
            this.showAccountingGroups()
            this.batch.ID_Business = idbusiness
            this.batch.ID_Branch = idbranch
            this.batch.Warehouse = this.data.ID_Warehouse

            this.accountinggroup.ID_Business = idbusiness
            this.accountinggroup.ID_Branch = idbranch
            this.accountinggroup.Warehouse = this.data.ID_Warehouse
            
        },
        methods: {
            closeAlert() {
            this.alert.show = false;
                
                if (this.alert.options == "deleteBatchComplete") {
                    this.showBatchs()
                    this.oldBatch = {}
                }
            
            },
            showAlert(type, header, body, options = null, data=null) {
                type = type == null ? "danger" : type;
                this.alert.type = type;
                this.alert.header = header;
                this.alert.body = body;
                this.alert.show = true;
                this.alert.options = options != null ? options : null;
                this.alert.data = data != null ? data : null;
            },
            acceptAlert() {
            this.alert.show = false;
            if (this.alert.options == "confirmDeleteBatch") {
                    this.deleteBatch(this.alert.data);
                }
            if (this.alert.options == "confirmDeleteAccountingGroup") {
                    this.deleteAccountingGroup(this.alert.data);
                }
            
            
            },
            confirmDeleteBatch(data){
                
                this.showAlert("question", "Confirmación", "¿Está seguro de eliminar el lote?","confirmDeleteBatch",data);
            },
            confirmDeleteAccountingGroup(data){
                
                this.showAlert("question", "Confirmación", "¿Está seguro de eliminar la familia contable?","confirmDeleteAccountingGroup",data);
            },
            showBatchs(){
                this.$API.warehouse.getBatchs(this.search)
                    .then(response => {
                        this.batchs = response
                    })
                    .catch(() => {
                        this.showAlert("danger", "Error","no se pudo realizar la operación")
                    });
            },

            addBatch(){
                this.updateBatch = false
                this.batch.ID_User_Insert = this.user
                this.$API.warehouse.addBatch(this.batch)
                    .then(response => {
                        if(response.message == "No se añadio el lote. El lote ya existe.") throw new Error("No se añadio el lote. El lote ya existe.")
                        this.showAlert("success", "Éxito",response.message)
                        this.showBatchs()
                    }).catch((error) => {
                        this.showAlert("danger", "Error",error.message)
                    })
            },

            showBatch(data){
                this.batch = data
                this.oldBatch = {
                    ID_ItemBatch: this.batch.ID_ItemBatch,
                    Warehouse: this.batch.Warehouse,
                    ExpirationDate: this.batch.ExpirationDate,
                    ID_Branch: this.batch.ID_Branch,
                    ID_Business: this.batch.ID_Business,
                    ID_User_Insert: this.batch.ID_User_Insert,
                    ID_User_Update: this.batch.ID_User_Update
                }
                this.updateBatch = true
            },

            toUpdateBatch(){
                this.updateBatch = false
                this.batch.ID_User_Update = this.user
                this.$API.warehouse.updateBatch({batchOld: this.oldBatch, batchNew: this.batch})
                    .then(response => {
                        this.showAlert("success", "Éxito",response.message);
                        this.showBatchs()
                        this.oldBatch = {}
                    })
                    .catch(() => {
                        this.showAlert("danger", "Error","no se pudo realizar la operación")
                    });
            },

            deleteBatch(data){
                this.updateBatch = false
                this.$API.warehouse.deleteBatch(data)
                    .then(response => {
                        this.showAlert("success", "Éxito",response.message, "deleteBatchComplete");
                        
                    })
                    .catch(() => {
                        this.showAlert("danger", "Error","no se pudo realizar la operación")
                    });
            },

            showAccountingGroups(){
                this.$API.warehouse.getAccountingGroups(this.search)
                    .then(response => {
                        this.accountinggroups = response
                    })
            },

            addAccountingGroup(){
                this.updateAccountinggroup = false
                this.accountinggroup.ID_User_Insert = this.user
                this.$API.warehouse.addAccountingGroup(this.accountinggroup)
                    .then(response => {
                        this.showAlert("success", "Éxito",response.message)
                        this.showAccountingGroups()
                    })
            },

            showAccountingGroup(data){
                this.accountinggroup = data
                this.oldAccountinggroup = {
                    ID_AccountingLine: this.accountinggroup.ID_AccountingLine,
                    AccountingLineName: this.accountinggroup.AccountingLineName,
                    ID_AccountingCategory: this.accountinggroup.ID_AccountingCategory,
                    AccountingCategoryName: this.accountinggroup.AccountingCategoryName,
                    ID_AccountingSubCategory: this.accountinggroup.ID_AccountingSubCategory,
                    AccountingSubCategoryName: this.accountinggroup.AccountingSubCategoryName,
                    Warehouse: this.accountinggroup.Warehouse,
                    ID_Branch: this.accountinggroup.ID_Branch,
                    ID_Business: this.accountinggroup.ID_Business,
                    ID_User_Insert: this.accountinggroup.ID_User_Insert,
                    ID_User_Update: this.accountinggroup.ID_User_Update
                }
                this.updateAccountinggroup = true
            },

            toUpdateAccountingGroup(){
                this.updateAccountinggroup = false
                this.accountinggroup.ID_User_Update = this.user
                this.$API.warehouse.updateAccountingGroup({accountinggroupOld: this.oldAccountinggroup, accountinggroupNew: this.accountinggroup})
                    .then(response => {
                        this.showAlert("success", "Éxito",response.message)
                        this.showAccountingGroups()
                        this.oldAccountinggroup = {}
                    })
                    .catch(() => {
                        this.showAlert("danger", "Error","no se pudo realizar la operación")
                    });
            },

            deleteAccountingGroup(data){
                this.updateAccountinggroup = false
                this.$API.warehouse.deleteAccountingGroup(data)
                    .then(response => {
                        this.showAlert("success", "Éxito",response.message)
                        this.showAccountingGroups()
                        this.oldAccountinggroup = {}
                    })
                    .catch(() => {
                        this.showAlert("danger", "Error","no se pudo realizar la operación")
                    });
            }
        }

    }   
</script>

<style scoped>
.box  {
  border-style: solid !important;
  border-color: black !important;
  border-width: 1px !important;
  background-color: white;
  padding: 0.5rem;
  border-radius: 15px;
  margin: 0.5rem;
}
.btn-add{
  background-color: #3498DB  !important;
  color: white;
}
.btnsave{
  background-color: #00A178 !important;
  color: white;
}
.btnclean{
  background-color: #F29D35 !important;
  color: white;
}
.btndelete{
  background-color: #E3191E !important;
  color: white;
}


</style>